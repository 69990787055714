import React, { createContext, useCallback, useContext, useState } from "react";
import Modal from "./Modal";

export const ModalContext = createContext({});

const ModalProvider = ({ children }) => {
  const [modalContent, setModal] = useState(null);
  const [modalSize, setModalSize] = useState("lg");

  const showModal = useCallback(
    (content, size='lg') => {
      console.log(content)
      setModal(content);
      if (size) setModalSize(size);
    },
    [setModal]
  );

  const hideModal = useCallback(() => {
    setModal(null);
    setModalSize("lg");
  }, []);

  return (
    <ModalContext.Provider
      value={{ showModal, hideModal }}
    >
      {children}
      <Modal
        open={!!modalContent}
        modalSize={modalSize}
        hideModal={hideModal}
        showModal={showModal}
      >
        {modalContent}
      </Modal>
    </ModalContext.Provider>
  );
};

export default ModalProvider;

export const useModal = () => useContext(ModalContext);
