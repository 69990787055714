import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "swiper/css/bundle";
import UserAuth from "./context/authProvider";
import UserGender from "./context/userGender";
import UserLanguage from "./context/userLanguage";
import AppData from "./context/appData";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import NotificationProvider from "./AppNotification/NotificationProvider";
import SocketProvider from "./context/socket";
import ModalProvider from "./context/ModalProvider";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60 * 60 * 24, // 1 day
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ModalProvider>
      <SocketProvider>
        <NotificationProvider>
          <QueryClientProvider client={queryClient}>
            <UserAuth>
              <UserGender>
                <UserLanguage>
                  <AppData>
                    <App />
                  </AppData>
                </UserLanguage>
              </UserGender>
            </UserAuth>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </NotificationProvider>
      </SocketProvider>
    </ModalProvider>
  </React.StrictMode>
);
