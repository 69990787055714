import React, { useEffect } from "react";
import './modal.css'

const Modal = ({open, hideModal, modalSize , children }) => {
  useEffect(() => {
    const body = document.documentElement;

    if (open) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [open]);

  return (
    <dialog
      onClick={hideModal}
      className={`modal ${open ? "open" : "close"}`}
      data-modal-size={modalSize}
    >
      <div className={"modal-container"} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </dialog>
  );
};

export default Modal;
