import { useAppTranslations } from "../api/translations";
import { useAppData } from "../context/appData";
import PieChart from "./PieChart";
import SelectField from "./SelectField";
import { useMemo, useState } from "react";
import { useUserLanguage } from "../context/userLanguage";

export default function ViewByGender() {
  const { language } = useUserLanguage();
  const { data: genderClick } = useAppData("genderClick");
  const { data: appTranslation, isLoading } = useAppTranslations();
  let date = new Date();
  const listOfYears = useMemo(() => {
    const years = [];
    for (let index = date.getFullYear(); index >= 2022; index--) {
      years.push(index);
    }
    return years;
  }, [date]);
  const [inputValues, setInputValues] = useState({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
  });
  return (
    <>
      <div className="w-full px-6 pb-6">
        <p className="text-white font-medium text-lg">
          {appTranslation?.translations?.adminPage?.viewByGender?.[language] ??
            "Number of views by gender"}
        </p>
        <div className="flex items-center justify-start gap-4 mt-2">
          <SelectField
            className="w-1/4"
            value={inputValues.year}
            setValue={(value) => {
              setInputValues((pre) => ({
                ...pre,
                year: value,
              }));
            }}
          >
            {listOfYears?.map((ele) => (
              <option value={ele}>{ele}</option>
            ))}
          </SelectField>
          <SelectField
            className="w-1/4"
            value={inputValues.month}
            setValue={(value) => {
              setInputValues((pre) => ({
                ...pre,
                month: value,
              }));
            }}
          >
            {[...Array(12)].map((_, i) => (
              <option value={i + 1}>{i + 1}</option>
            ))}
          </SelectField>
        </div>
      </div>
      <PieChart data={genderClick} options={inputValues} />
    </>
  );
}
