import { useModal } from "../context/ModalProvider";
import Loader from "./Loader";

const RenderIframe = ({ url }) => {
  const { hideModal } = useModal();
  return (
    <div className="max-h-[70vh] overflow-y-auto relative">
      <button
        className="rounded-full border border-black w-6 h-6 text-black float-right"
        onClick={hideModal}
      >
        X
      </button>
      <div className="w-full h-full" id="iframe-loader">
        <Loader className='!absolute !w-[calc(100%+20px)] !h-full !bg-slate-200' loadClassName="!border-t-[3px] !border-blue" />
      </div>

      <iframe
        src={url}
        className="w-full h-[calc(70vh-30px)]"
        onLoad={() => {
          const loaderEle = document.getElementById("iframe-loader");
          loaderEle.style.display = "none";
        }}
      />
    </div>
  );
};

export default RenderIframe;
